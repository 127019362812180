<template>
  <div>

<!--    <div v-if="articleDetail.mainImage!=null && articleDetail.mainImage!=''" class="tip"-->
<!--         v-bind:style="{ 'background-image': 'url(' + articleDetail.mainImage + ')','background-repeat':'no-repeat','background-size':'cover','opacity':'0.8' }" >-->
<!--      <img src="@/assets/eye.gif" class="eye"/>-->
<!--    </div>-->

<!--    <div class="simpleTip" v-else>-->
<!--      <img src="@/assets/eye.gif" class="eye"/>-->
<!--    </div>-->

    <div style="width: 100vw;display: flex;align-items: center;justify-content: center;flex-direction: column;" class="tip-box">
      <img src="@/assets/eye.gif" class="eye"/>
      <img :src="articleDetail.mainImage" style="max-width: 900px;border-radius: 50px;" v-if="mobileFlag==false">
      <img :src="articleDetail.mainImage" style="max-width: 90vw;border-radius: 50px;" v-else>
    </div>

    <div class="container">
      <div class="text-center">
        <h1 class="headline">{{ articleDetail.title }}</h1>
        <p>
          发布时间: {{ articleDetail.createTime }}
        </p>
      </div>
      <hr>
<!--      <div>-->
<!--        <vs-button icon color="danger" border :active="active == 2" @click="active = 2" animation-type="scale">-->
<!--          <i class='bx bxs-heart' ></i>-->
<!--          <template #animate >收藏</template>-->
<!--        </vs-button>-->
<!--        <vs-button icon relief danger animation-type="rotate">-->
<!--          <i class='bx bx-like'></i>-->
<!--          <template #animate >点赞</template>-->
<!--        </vs-button>-->

<!--        <vs-button color="#ff3e4e" circle icon floating>-->
<!--          <i class='bx bx-up-arrow-alt' ></i>-->
<!--        </vs-button>-->

<!--        <vs-button circle icon disabled floating>-->
<!--          <i class='bx bxs-chat' ></i>-->
<!--        </vs-button>-->

<!--      </div>-->
      <div class="markdownContent" style="display: flex;justify-content: center;" v-if="mobileFlag==false">
        <v-md-preview :text="articleDetail.content" ></v-md-preview>
      </div>
      <div class="markdownContent" v-else>
        <v-md-preview :text="articleDetail.content" ></v-md-preview>
      </div>
      <div class="kkk">
        <PostTags :postTags="articleTag" />
      </div>
<!--      <div class="kkk">-->
<!--        <PostNavBtn  :prev="prevArticle" :next="nextArticle" />-->
<!--      </div>-->
      <Talk class="kkk" :id="articleId" />

    </div>
  </div>
</template>

<script>
import PostNavBtn from '@/components/PostNavBtn.vue'
import PostTags from '@/components/PostTags.vue'
import Talk from '@/components/Talk'

import { getArticleDetail } from '@/api/commonNetRequest'

export default {
  name: 'ViewPost',
  components: {
    PostNavBtn,
    PostTags,
    Talk
  },
  data: function () {
    return {
      articleId: this.$route.path.split('posts/')[1],
      articleDetail:{},//文章详情
      articleTag:[],//文章标签
      prevArticle: {
        title: null,
        id: null
      },
      nextArticle: {
        title: null,
        id: null
      },
      mobileFlag:false
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag!=null
    },
    async getPost() {
      this.articleId = this.$route.path.split('posts/')[1]
      const loading = this.$vs.loading({type:'corners'})
      let {code,data} =await getArticleDetail(this.articleId).catch(()=>loading.close())
      loading.close()
      if(code==0){
        this.articleDetail=data
        this.articleTag=JSON.parse(data.articleTag).tag
        this.nextArticle=data.prevArticle
        this.prevArticle=data.nextArticle
      }

    }
  },
  mounted: function () {
    this.getPost()
    this.mobileFlag=this.isMobile()
  },
  watch: {
    $route (to, from) {
      this.getPost()
    }
  }
}
</script>

<style>
.markdownContent::-webkit-scrollbar {
  width: 0; /* 隐藏滚动条 */
}
.cover {
  width: 100vw;
}

.margin {
  margin-left: 20px;
}

.post img {  /* limit image max width to 100vw in a post */
  max-width: 100%;
}
.markdownContent{
  width: 100%;
  padding-left: 10px;
  overflow-y: scroll;
}

/*.eye{
  width: 6rem;
  margin-top: -70px;
  margin-left: 70px;
  border-radius: 45px;
}*/

.tip-box .eye{
  width: 6rem;
  border-radius: 45px;
  position: relative;
  top: 20px;
}
.kkk{
  width: 80vw;
  margin-left: 10vw;
}


</style>
