<template>
  <div class="container text-center">
    <h1>
      关于我
    </h1>
    <hr />
  </div>
</template>

<script>


export default {
  name: 'AboutMe',
  components: {

  },
  mounted: function () {
    //this.changeTitle('About')
  }
}
</script>
