<template>
  <div class="footer">
    <div class="container text-center">
      <h3>{{ blogConf.blogTitle }}</h3>
      <p><i>{{ blogConf.blogDesc }}</i></p>
      <br />
      <p >
        {{ blogConf.blogCopyright }}
      </p>
      <div style="display: flex;margin: 0 auto;justify-content: center;align-items: center;">
        <p v-if="blogConf.blogPutOnRecord">
          <a :href="blogConf.blogPutOnRecordUrl" rel="noreferrer" target="_blank" style="cursor: pointer">{{ blogConf.blogPutOnRecord }}</a>
        </p>
        <p v-if="blogConf.policeRecord" style="margin-left: 10px;">
          <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/html/police.png" style="width: 16px;height: 16px;margin-right: 5px;">
          <a :href="blogConf.policeRecordUrl" rel="noreferrer" target="_blank" style="cursor: pointer">{{ blogConf.policeRecord }}</a>
        </p>
      </div>

    </div>
  </div>
</template>

<script>


export default {
  name: 'Footer',
  props:['blogConf']
}
</script>
