import { render, staticRenderFns } from "./ExchangeLink.vue?vue&type=template&id=075f2357&scoped=true&"
import script from "./ExchangeLink.vue?vue&type=script&lang=js&"
export * from "./ExchangeLink.vue?vue&type=script&lang=js&"
import style0 from "./ExchangeLink.vue?vue&type=style&index=0&id=075f2357&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "075f2357",
  null
  
)

export default component.exports