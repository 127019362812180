<template>
  <div >
    <vs-row justify="space-between">
      <vs-col w="2" v-if="next!=null && next.id!=null">
        <vs-button
          size="large"
          block
          border
          :to="`/posts/${next.id}`"
        >
          <i class="bx bx-left-arrow-alt" ></i>
          <template #animate >
            {{ next.title | ellipsis}}
          </template>

        </vs-button>
      </vs-col>
      <vs-col w="2"></vs-col>
      <vs-col w="2" v-if="prev!=null && prev.id!=null" class="align-right">
        <vs-button
          size="large"
          block
          border
          :to="`/posts/${prev.id}`"
        >
          <template #animate >
            {{ prev.title | ellipsis}}
          </template>

          <i class="bx bx-right-arrow-alt" ></i>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: 'PostNavBtn',
  props: [
    'next',
    'prev'
  ],
  filters: {
    // 当标题字数超出时，超出部分显示“...”
    ellipsis (docName) {
      if (!docName) return ''
      if (docName.length > 8) {
        return docName.slice(0, 8) + '...'
      }
      return docName
    }
  },
}
</script>

<style scoped>
.align-right {
  text-align: right !important;
}
</style>
