<template>
  <div class="talkView">
    <div class="title"><span class="talk hljs-talk-name">评论</span><span class="num">{{commentData.length}}</span></div>
    <div class="talkInput">
      <vs-input label="" v-model="msg" placeholder="评论你的想法" class="input"/>
    </div>
    <div class="talkInput submitDiv">
      <vs-button @click="handleClick" v-if="buttonStatus" :icon="success" :upload="sending" :color="success ? 'success' : 'primary'">
        <span v-if="!success"><i class='bx bx-mail-send' ></i>发布评论</span>
        <i class='bx bx-check' v-else ></i>
      </vs-button>
      <vs-button disabled v-if="!buttonStatus" :icon="success" :upload="sending" :color="success ? 'success' : 'primary'">
        <span v-if="!success"><i class='bx bx-mail-send' ></i>发布评论</span>
        <i class='bx bx-check' v-else ></i>
      </vs-button>
    </div>
    <div v-for="item in commentData" :key="item.id">
      <div class="replayItem" >
        <vs-avatar>
          <i class='bx bx-user'></i>
        </vs-avatar>
        <span class="name hljs-talk-name">{{ item.username }}</span>
        <span class="point">·</span>
        <span class="time">{{ item.createTime }}</span>
      </div>
      <div class="content">{{ item.contentTxt }}</div>
    </div>

  </div>
</template>
<script>
import { commit } from '@/api/userNetRequest'
import { getArticleComments } from '@/api/commonNetRequest'

export default{
  name:'Talk',
  props:['id'],
  data:() => ({
    msg: '',
    commentData:[],
    sending: false,
    success: false,
    buttonStatus:false
  }),
  mounted () {
    let userName=window.localStorage.getItem('userName')
    if(userName!=null){
      this.buttonStatus=true
    }
    this.getData()
  },
  methods:{
    async getData(){
      this.sending=false
      this.success=false
      let {data} =await getArticleComments(this.id)
      this.commentData=data
    },
    handleClick() {
      this.sending = true

      commit({articalId:this.id,content:this.msg})
        .then(({code,data})=>{
          setTimeout(()=>{
            this.sending = false
            this.success = !this.success
            this.getData()
            this.msg=''
          },500)
        })
        .catch((err)=>{
          this.$vs.notification({ color:'danger', progress: 'auto', title: '用户评论', text: `评论失败` })
        })

    },
  }
}
</script>
<style lang="scss" scoped>
  .talkView{
    max-width: 1000px;
    margin: 0 auto;
  }
  .title{
    .talk{
      transition: all 0s ease 0s;
      font-size: 20px;
      display: inline-block;
      font-weight: 500;
      border-radius: 0px;
    }
    .num{
      transition: all 0s ease 0s;
      color: rgb(136, 136, 136);
      font-size: 20px;
      display: inline-block;
      font-weight: 500;
      border-radius: 0px;
      margin-left: 10px;
    }
  }
  .talkInput{
    margin-top: 10px;
    ::v-deep .vs-input{
      width: 100%;
    }
  }
  .submitDiv{
    display:flex;
    overflow:auto;
    justify-content:flex-end;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap:wrap;
    flex-direction:row;
  }
  .replayItem{
    display:flex;
    overflow:auto;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap:wrap;
    flex-direction:row;

    .name{
      transition: all 0s ease 0s;
      font-size: 14px;
      display: block;
      line-height: 20px;
      font-weight: bold;
      border-radius: 0px;
      margin-right: 4px;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 44px;
      margin-left: 10px;
    }
    .point{
      transition: all 0s ease 0s;
      color: rgb(186, 186, 186);
      font-size: 12px;
      display: inline-block;
      border-radius: 0px;
      margin-right: 2px;
      line-height: 44px;
    }
    .time{
      transition: all 0s ease 0s;
      color: rgb(186, 186, 186);
      font-size: 12px;
      display: inline-block;
      border-radius: 0px;
      margin-right: 8px;
      line-height: 44px;
    }
  }
  .content{
    color: rgb(102, 102, 102);
    font-size: 14px;
    word-break: break-word;
    margin-top: 8px;
    line-height: 24px;
    display: block;
    white-space: pre;
    margin-left: 54px;
  }
</style>
