import axios from 'axios'
import vueDomain from '@/main.js'

const service = axios.create({
  baseURL: '/api',
  withCredentials: false,
  timeout: 5000,//请求超时
})

// 请求拦截器
service.interceptors.request.use(
  config => {

    //判断上次登录时间是否超过了24小时
    let lastLogin=window.localStorage.getItem('loginTime')
    if(lastLogin!=null){
      let day=(Date.now()-parseInt(lastLogin)) /1000 /60 /60;
      if(day>24){
        window.localStorage.removeItem('userName')
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
      }
    }


    let accessToken=window.localStorage.getItem('accessToken')
    if(accessToken!=null){
      config.headers['token'] = accessToken
    }

    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {

    const res = response.data

    // 接口返回异常
    if (res.code !== 0) {
      if(res.errorMsg=='refreshToken校验失败' || res.errorMsg=='token校验失败'){
        window.localStorage.removeItem('userName')
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        vueDomain.$vs.notification({
          color:'danger',
          progress: 'auto',
          title: '提示',
          text: `您的登录已过期，请重新登录。`
        })
      }
      return Promise.reject(res.errorMsg)
    } else {
      return res
    }


  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
