<template>
  <div class="text-center">
    <p>此处没有任何内容。</p>
  </div>
</template>

<script>
export default {
  name: 'Nothing',
  data: function () {
    return {

    }
  }
}
</script>
