<template>
  <div id="app" class="content">
    <Navbar @showLogin="show" :blog-conf="blogConf"/>
    <router-view />
    <Login ref="loginView" />
    <br v-for="index in 10" :key="index" />
    <div class="wrapper">
      <Footer :blog-conf="blogConf" />
    </div>
  </div>
</template>

<script>
import { getBlogSetting } from '@/api/commonNetRequest'

import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'App',
  data:function (){
    return {
      blogConf:{},//博客设置
    }
  },
  components: {
    Navbar,
    Footer,
    Login
  },
  created () {
    this.initData()
  },
  methods: {
    async initData(){
      let responce=await getBlogSetting()
      if(responce.code==0){
        this.blogConf=responce.data
      }
    },
    show(){
      console.log('kkk')
      this.$refs.loginView.changeShow()
    }
  }
}
</script>

<style>
.w-full {
  width: 100% !important;
}

.w-full .vs-card__text {
  width: 100%;
}
</style>
