<template>
  <div class="container">
    <h1 class="text-center">所有文章</h1>
    <hr />
    <div v-if="total">
      <br />
      <div class="grid-3_xs-1_sm-2_md-2">
        <div :key="index" v-for="(article, index) in pageData" class="col center">
          <PostCard :article="article" />
        </div>
      </div>
      <div class="center con-pagination">
        <vs-pagination v-model="curPage" :length="allPage" not-margin progress circle />
      </div>
    </div>
    <Nothing v-else />
  </div>
</template>

<script>
import PostCard from '@/components/PostCard.vue'
import Nothing from '@/components/Nothing.vue'

import { getArticlePage } from '@/api/commonNetRequest'

export default {
  name: 'ViewPosts',
  components: {
    PostCard,
    Nothing
  },
  data: function () {
    return {
      pageData:[],//当前页数据
      curPage: 1,
      allPage:0,
      total:0
    }
  },
  created: function () {
    //this.changeTitle('Posts')
    this.getArticle()
  },
  watch:{
    curPage(newVal,oldVal){
      this.getArticle()
    }
  },
  methods: {
    getArticle: async function (){
      const loading = this.$vs.loading({type:'corners'})
      const {code,data}=await getArticlePage(this.curPage).catch(()=>loading.close())
      loading.close()
      if(code==0){
        this.pageData=data.records
        for (const article of this.pageData) {
          article.tag=JSON.parse(article.articleTag).tag
        }
        this.allPage=data.pages
        this.total=data.total
      }
    }

  }
}
</script>
