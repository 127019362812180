<template>
  <router-link :to="`/posts/${article.id}`">
    <vs-card class="post-card">
      <template #title>
        <h3>{{ article.title }}</h3>
        <small>{{ article.createTime }}</small>
      </template>
      <template #img>
        <img v-if="article.mainImage!=null && article.mainImage!=''" :src="article.mainImage" alt />
        <img v-else src="@/assets/defaultBg.jpg" alt />
      </template>
      <template #text>
        <p class="post-txt" v-html="article.articleDesc"></p>
        <small class="post-card-tag">
          <b :key="i" v-for="(tag, i) in article.tag" style="margin-right: 5px">
            {{ tag }}
          </b>
        </small>
      </template>
<!--      <template #interactions>-->
<!--        <vs-tooltip right shadow interactivity>-->
<!--          <Avatar />-->
<!--          <template #tooltip>-->
<!--            作者：-->
<!--            <b>Ll's Blog</b>-->
<!--          </template>-->
<!--        </vs-tooltip>-->
<!--      </template>-->
    </vs-card>
  </router-link>
</template>

<script>
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'PostCard',
  props: [
    'article'
  ],
  data: function () {
    return {


    }
  },
  components: {
    Avatar
  }
}
</script>

<style>
.post-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: auto; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.post-card .vs-card {
  //height: 380px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.vs-card__img img{
  width: 90% !important;
  margin-top: 20px;
}

.post-card-tag {
  //position: absolute;
  //bottom: 20px;
  margin-top: 20px;
}
</style>
