
<template>
  <div class="center">
    <vs-dialog v-model="active">
      <template #header>
        <h4 class="not-margin">
          <b v-show="!register">用户登录</b>
          <b v-show="register">用户注册</b>
        </h4>
      </template>


      <div class="con-form" v-show="!register">
        <vs-input v-model="login_email" placeholder="邮箱" label-placeholder="邮箱">
          <template #icon>
            @
          </template>
        </vs-input>
        <vs-input type="password" v-model="login_password" placeholder="密码" label-placeholder="密码">
          <template #icon>
            <i class='bx bxs-lock'></i>
          </template>
        </vs-input>
        <div class="flex">
          <vs-checkbox v-model="remember">记住密码</vs-checkbox>
<!--          <a href="#">忘记密码?</a>-->
        </div>
      </div>

      <div class="con-form" v-show="register">
        <vs-input v-model="username" placeholder="用户名" label-placeholder="用户名">
          <template #icon>
            @
          </template>
        </vs-input>
        <vs-input v-model="email" placeholder="邮箱" label-placeholder="邮箱">
          <template #icon>
            @
          </template>
        </vs-input>
        <vs-input type="password" v-model="password" placeholder="密码" label-placeholder="密码">
          <template #icon>
            <i class='bx bxs-lock'></i>
          </template>
        </vs-input>
        <vs-input type="password" v-model="check_password" placeholder="确认密码" label-placeholder="确认密码">
          <template #icon>
            <i class='bx bxs-lock'></i>
          </template>
        </vs-input>
        <vs-input v-model="emailCode" placeholder="邮箱验证码"  label-placeholder="验证码">
          <template #icon>
            <i class='bx bxs-pin'></i>
          </template>
        </vs-input>
        <div class="flex">
          <vs-button block @click="send">
            {{ sendTxt }}
          </vs-button>
        </div>
      </div>

      <template #footer >
        <div class="footer-dialog" v-show="!register">
          <vs-button block @click="userLogin">
            登录
          </vs-button>

          <div class="new">
            没有账号? <a href="#" @click="registerUser">点击注册</a>
          </div>
        </div>
        <div class="footer-dialog" v-show="register">
          <div class="new"><a href="#" @click="registerUser">返回登录</a></div>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>
<script>
import { sendCode,register,login } from '@/api/userNetRequest'

export default {
  data:() => ({
    sendFlag:false,//是否发送了验证码
    sendTxt:'发送验证码',
    active: false,
    register:false,
    login_email: '',
    login_password: '',
    username:'',
    email: '',
    emailCode: '',
    password: '',
    check_password:'',
    remember: false
  }),
  mounted () {
    let uName=window.localStorage.getItem('uName')
    let uPwd=window.localStorage.getItem('uPwd')
    if(uName!=null && uPwd!=null){
      this.login_email=uName
      this.login_password=uPwd
    }
  },
  methods:{
    changeShow(){
      this.active=true
    },
    registerUser(){
      this.register=!this.register
    },
    userLogin(){
      if(this.login_email=='' || this.login_password=='' ){
        this.$vs.notification({ color:'danger', progress: 'auto', title: '用户登录', text: '请输入邮箱和密码' })
        return
      }
      if(this.remember){
        window.localStorage.setItem('uName', this.login_email)
        window.localStorage.setItem('uPwd', this.login_password)
      }else{
        window.localStorage.removeItem('uName')
        window.localStorage.removeItem('uPwd')
      }
      login({emailAddr:this.login_email,password:this.login_password})
        .then(({code,data})=>{
          this.$vs.notification({ color:'success', progress: 'auto', title: '用户登录', text: `登录成功。` })
          window.localStorage.setItem('userName', data.userName)
          window.localStorage.setItem('accessToken', data.accessToken)
          window.localStorage.setItem('refreshToken', data.refreshToken)
          window.localStorage.setItem('loginTime', Date.now())
          this.active=false
          setTimeout(()=>{
            location.reload()
          },500)
        })
        .catch((err)=>{
          this.$vs.notification({ color:'danger', progress: 'auto', title: '用户登录', text: err })
        })
    },
    send(){
      if(this.sendFlag){//调用注册接口
        if(this.username=='' || this.email=='' || this.emailCode=='' || this.password==''|| this.check_password==''){
          this.$vs.notification({ color:'danger', progress: 'auto', title: '用户注册', text: '请填写完整' })
          return
        }
        if(this.password!=this.check_password){
          this.$vs.notification({ color:'danger', progress: 'auto', title: '用户注册', text: '两次密码输入不一致' })
          return
        }
        register({username:this.username,emailAddr:this.email,password:this.password,code:this.emailCode})
          .then(()=>{
            this.$vs.notification({ color:'success', progress: 'auto', title: '用户注册', text: `注册成功，请返回登录。` })
          })
          .catch((err)=>{
            this.$vs.notification({ color:'danger', progress: 'auto', title: '用户注册', text: `注册失败` })
          })
      }else{
        sendCode({emailAddr:this.email})
          .then(({code,errorMsg})=>{
            this.$vs.notification({ color:'success', progress: 'auto', title: '验证码发送', text: `发送验证码成功。` })
            this.sendFlag=true
            this.sendTxt='立即注册'
          })
          .catch((err)=>{
            this.$vs.notification({ color:'danger', progress: 'auto', title: '验证码发送', text: err })
          })

      }

    }
  }
}
</script>
<style >
/*rgba(var(--vs-vsColor), 1)*/
/*var(--vs-var)*/
.not-margin {
  margin: 0px;
  font-weight: normal;
  padding: 10px;
}
.con-form {
  width: 100%;
}
.con-form .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.con-form .flex a {
  font-size: 0.8rem;
  opacity: 0.7;
}
.con-form .flex a:hover {
  opacity: 1;
}
.con-form .vs-checkbox-label {
  font-size: 0.8rem;
}
.con-form .vs-input-content {
  margin: 10px 0px;
  width: calc(100%);
}
.con-form .vs-input-content .vs-input {
  width: 100%;
}
.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100%);
}
.footer-dialog .new {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 0.7rem;
}
.footer-dialog .new a {
  /*color: getColor('primary') !important;*/
  margin-left: 6px;
}
.footer-dialog .new a:hover {
  text-decoration: underline;
}
.footer-dialog .vs-button {
  margin: 0px;
}

</style>

