<template>
  <div style="width: 96vw;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;">
    <h1 class="text-center">视频加密播放器</h1>
    <hr />
    <br />
    <div class="desc-box">
      功能简介：加密mp4视频 <br><br>
      注意：目前只能加密mp4格式视频，其他视频请先把格式转为mp4格式！<br><br>
      应用场景：<br>
      1.因视频版权问题，无法分享<br>
      2.特殊教学视频，防止泄露<br>
    </div>
    <div class="img-box" v-if="isMobile() !== null">
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p1.png" class="full-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p2.png" class="full-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p3.png" class="full-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p4.png" class="full-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p5.png" class="full-img"/>
    </div>
    <div class="img-box"  v-else>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p1.png" class="v-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p2.png" class="v-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p3.png" class="v-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p4.png" class="v-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/lvplayer/p5.png" class="v-img"/>
    </div>
    <div class="desc-box">
      <div style="padding: 20px;background-color: #1A5CFF;color: white;font-weight: bold;border-radius: 20px;cursor: pointer;" @click="download">播放器安装包下载</div>
    </div>
<!--    <div class="grid-3_xs-1_sm-2_md-2" v-if="blogProject.length">-->
<!--      <div-->
<!--        v-for="(project, index) in blogProject"-->
<!--        :key="index"-->
<!--        class="col center"-->
<!--      >-->
<!--        <a :href="project.projUrl" target="_blank">-->
<!--          <vs-card-->
<!--            type="5"-->
<!--          >-->
<!--            <template #title>-->
<!--              <h3>{{ project.projName }}</h3>-->
<!--            </template>-->
<!--            <template #text>-->
<!--              <p>-->
<!--                {{ project.projDesc }}-->
<!--              </p>-->
<!--            </template>-->
<!--            <template #img>-->
<!--              <img v-if="project.projImg!=null && project.projImg!=''" :src="project.projImg" />-->
<!--              <img v-else src="@/assets/defaultBg.jpg">-->
<!--            </template>-->
<!--            <template #interactions>-->
<!--              <vs-tooltip right>-->
<!--                <vs-button size="large" dark icon :href="project.projGitpath" blank>-->
<!--                  <i class="bx bxl-github"></i>-->
<!--                </vs-button>-->
<!--                <template #tooltip>-->
<!--                  GitHub-->
<!--                </template>-->
<!--              </vs-tooltip>-->
<!--            </template>-->
<!--          </vs-card>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <Nothing v-else />-->
  </div>
</template>

<script>
import { getBlogProject } from '@/api/commonNetRequest'

import Nothing from '@/components/Nothing.vue'


export default {
  name: 'ViewProjects',
  components: {
    Nothing
  },
  data: function () {
    return {
      blogProject:[]
    }
  },
  created () {
    this.initData()
  },
  mounted: function () {
    //this.changeTitle('Projects')
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      console.log('flag',flag)
      return flag;
    },
    async initData(){
      const responce=await getBlogProject()
      if(responce.code==0){
        this.blogProject=responce.data
      }
    },
    download(){
      window.location.href="https://llsblog.oss-cn-beijing.aliyuncs.com/img/exe/LvplayerSetup_v1.0.msi"
    }
  }
}
</script>
<style>
.desc-box{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.img-box{
  width: 96vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.full-img{
  width: 100%;
  margin-top: 15px;
}
.v-img{
  width: 40vw;
  margin: 15px;
}
</style>
