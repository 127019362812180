<template>
  <div>
    <div v-if="isMobile() !== null">
      <!--显示手机端布局-->
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/%E4%B8%BB%E5%AE%A3%E4%BC%A0.png" class="max-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/%E5%AE%A3%E4%BC%A0.png" class="max-img"/>
    </div>
    <div v-else>
<!--      <div class="mainImg">-->
<!--        <p class="mainTxt animate__animated animate__fadeInUp hljs-main-txt">保持热爱，奔赴星海。</p>-->
<!--      </div>-->
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/%E4%B8%BB%E5%AE%A3%E4%BC%A0.png" class="mid-img"/>
      <img src="https://llsblog.oss-cn-beijing.aliyuncs.com/img/%E5%AE%A3%E4%BC%A0.png" class="mid-img"/>
    </div>

<!--    <div class="container">-->
<!--      <h2>热门文章</h2>-->
<!--      <hr/>-->
<!--      <div class="grid-3_xs-1_sm-2_md-2" v-if="recentArticles.length">-->
<!--        <div :key="index" v-for="(article, index) in recentArticles" class="col">-->
<!--          <PostCard :article="article" class="center" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <Nothing v-else />-->
<!--    </div>-->
  </div>
</template>

<script>
import PostCard from '@/components/PostCard.vue'
import Nothing from '@/components/Nothing.vue'

import { getRecentArticle } from '@/api/commonNetRequest'


export default {
  name: 'Home',
  components: {
    PostCard,
    Nothing
  },
  data: function () {
    return {
      recentArticles:[],//最近发表
    }
  },
  created () {
    this.initData()
  },
  mounted: function () {
    //this.changeTitle('Home')
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      console.log('flag',flag)
      return flag;
    },
    async initData(){
      const loading = this.$vs.loading({type:'corners'})
      let articleRes=await getRecentArticle().catch(()=>loading.close())
      loading.close()
      if(articleRes.code==0){
        this.recentArticles=articleRes.data
        for (const article of this.recentArticles) {
          article.tag=JSON.parse(article.articleTag).tag
        }
      }
    }
  }
}
</script>
<style>
.mid-img{
  width: 60vw;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
}
.max-img{
  width: 100vw;
  margin-top: 20px;
}
.mainImg{
  background: url('../assets/title.jpg') no-repeat top ,100% 100%;
  max-height: 600px;
  min-width: 600px;
  overflow: hidden;
}
.mainTxt{
  font-size: 6em;
  text-align: center;
  line-height: 600px;
  overflow: hidden;
  font-family: 'ShuFa';
}
.mainImg::after{
  content: "";
  display: block;
  padding-bottom: 25%;
}
.mainBg{
  width: 100%;
}
</style>
