import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'boxicons'
import 'boxicons/css/boxicons.min.css'
import 'animate.css';


Vue.config.productionTip = false

Vue.use(Vuesax, {})



/**
 * markdown
 */
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

// 高亮代码行插件
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';

// 快捷复制插件
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';


import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
  Hljs: hljs,
})
VMdPreview.use(createHighlightLinesPlugin());
VMdPreview.use(createCopyCodePlugin());
Vue.use(VMdPreview);

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

let VueDomain=new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default  VueDomain

console.log(`

.-.     .-.        _    .-..-.     .-..--.   .-.     .-..-..-. .-..--.
| |     | |      _\`-'_  | | ~      | | ~~.-. | |     | | ~ | | | | ~~
| |     | |     \`-' \`-'  \\|        | |.--.~  | |     | |   | | | |  __
| |     | |                |\\      | | ~~.-. | |     | |   | | | | \`. |
| | __  | | __           _ | |     | |.--.~  | | __  | | _ | | | | _| |
\`-'\`--' \`-'\`--'         \`-'\`-'     \`-' ~~    \`-'\`--' \`-'\`-'\`-' \`-'\`---'


Powered by %cLlTeach%cv0.1.0%c

GitHub: https://github.com/lvdashi\nPlease star & fork to support the author!`,
  'background-color: #1A55ED; padding: 7px; color: #fff;',
  'background-color: #FCBF23; color: #000; padding: 7px;',
  'font-weight: bold')
