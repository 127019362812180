import request from '@/utils/request'

export function getBlogSetting() {
  return request({
    url: `/blog/conf`,
    method: 'get',
  })
}

export function getRecentArticle() {
  return request({
    url: `/article/list`,
    method: 'get',
  })
}

export function getBlogMenu() {
  return request({
    url: `/blogMenu/list`,
    method: 'get',
  })
}

export function getBlogProject() {
  return request({
    url: `/blogProjects/list`,
    method: 'get',
  })
}

export function getBlogWebsite() {
  return request({
    url: `/blogWebsite/list`,
    method: 'get',
  })
}

export function getArticlePage(pageNum) {
  return request({
    url: `/article/page`,
    method: 'get',
    params:{
      showFlag:'0',
      pageSize: 6,
      pageNum: pageNum
    }
  })
}

export function getArticleDetail(id) {
  return request({
    url: `/article/${id}`,
    method: 'get',
  })
}
export function getArticleComments(id) {
  return request({
    url: `/articleComments/list?articalId=${id}`,
    method: 'get',
  })
}
