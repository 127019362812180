import request from '@/utils/request'

export function sendCode(data) {
  return request({
    url: `/open/sendCode`,
    method: 'post',
    data
  })
}
export function register(data) {
  return request({
    url: `/open/register`,
    method: 'post',
    data
  })
}
export function login(data) {
  return request({
    url: `/open/email/login`,
    method: 'post',
    data
  })
}
export function commit(data) {
  return request({
    url: `/articleComments`,
    method: 'post',
    data
  })
}

