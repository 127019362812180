<template>
  <div class="container">
    <h1 class="text-center">所有链接</h1>
    <hr />
    <div class="grid-3_xs-1_sm-2_md-2" v-if="blogWebsite.length">
      <div
        :key="index"
        v-for="(link, index) in blogWebsite"
        class="col w-full"
      >
        <ExchangeLink
          :link="link.linkUrl"
          :title="link.linkTitle"
          :desc="link.linkDesc"
          class="center"
        />
      </div>
    </div>
    <Nothing v-else />
  </div>
</template>

<script>
import {  getBlogWebsite } from '@/api/commonNetRequest'

import ExchangeLink from '@/components/ExchangeLink.vue'
import Nothing from '@/components/Nothing.vue'



export default {
  name: 'Links',
  data: function () {
    return {
      blogWebsite:[]
    }
  },
  components: {
    ExchangeLink,
    Nothing
  },
  created () {
    this.initData()
  },
  methods:{
    async initData(){
      const loading = this.$vs.loading({type:'corners'})
      const responce=await getBlogWebsite().catch(()=>loading.close())
      loading.close()
      if(responce.code==0){
        this.blogWebsite=responce.data
      }
    }
  }
}
</script>
